import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import {
  HalfWidthBlock,
  Medailon,
  PaddingWrapper,
  ReferenceContainer,
  TitleH2,
} from "@igloonet-web/shared-ui"
import ReferenceBanner from "@igloonet-web/shared-ui/components/reference/reference-banner"
import ReferenceBannerContent from "@igloonet-web/shared-ui/components/reference/reference-banner-content"

import Logo from "../../../images/reference/student-agency/logoSA.png"
import Header from "../../../images/reference/student-agency/header-marketing.png"
import SA from "../../../images/reference/student-agency/sa.jpg"

class Student extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    const pozadi = {
      backgroundImage: `linear-gradient(rgba(24,25,26,0.9),rgba(2,3,4,0.4)),url(${SA})`,
    }

    return (
      <Layout>
        <Helmet>
          <title>Individuální školení pro STUDENT AGENCY | igloonet</title>
          <meta
            name="description"
            content="Marketing, vývoj a hosting pod střechou jedné firmy. Programujeme moderní eshopy, poskytujeme prvotřídní hostingové služby a tvoříme marketingové strategie na míru."
          />
        </Helmet>

        <ReferenceBanner
          styles={pozadi}
          logo={Logo}
          alt="logo"
          extodkaz="//student-agency.cz/"
          heading="Individuální školení pro STUDENT AGENCY"
          odstavec="září 2014 - únor 2015"
        >
          <ReferenceBannerContent
            image={{
              src: Header,
            }}
            mainText="STUDENT AGENCY je největší cestovní agentura v České republice, největší prodejce letenek i jízdenek na trhu a největší agentura pro prodej jazykových a pracovních pobytů v zahraničí."
          />
        </ReferenceBanner>

        <ReferenceContainer>
          <HalfWidthBlock>
            <PaddingWrapper>
              <TitleH2>Zadání</TitleH2>
              <p className="text-center">
                Ukázat marketingovému týmu STUDENT AGENCY novinky v online
                marketingu a přinést čerstvé nápady zvenčí.
              </p>
            </PaddingWrapper>

            <PaddingWrapper>
              <TitleH2>Rozsah projektu</TitleH2>
              <ul>
                <li>brandová komunikace</li>
                <li>tvorba marketingových kampaní</li>
                <li>SEO</li>
                <li>PPC</li>
              </ul>
            </PaddingWrapper>

            <PaddingWrapper>
              <TitleH2>Historie spolupráce</TitleH2>
              <p>
                Vzhledem k šířce záběru témat, kterými chtěl klient se svým
                týmem projít, jsme školení rozdělili do série workshopů. Ty
                probíhaly od září 2014 do února 2015. Provedli jsme inhouse
                marketingový tým STUDENT AGENCY procesem tvorby online kampaní,
                brandingem i novinkami v PPC a SEO za poslední léta. U všech
                témat jsme kladli důraz na možnost okamžitého využití.
                Nepřednášeli jsme pouze teoretické základy, ale vše ukazovali na
                konkrétních příležitostech.
              </p>
            </PaddingWrapper>
          </HalfWidthBlock>

          <Medailon
            wide
            text="Veškeré marketingové aktivity si řídíme sami, proto je pro nás nezbytné získat občas nový pohled na věc - mimo naši bublinu."
            name="Martin Chomča"
            position="HR Generalista"
          />
        </ReferenceContainer>

        <ContactForm contact="adam" />
      </Layout>
    )
  }
}

export default Student
